import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/FactFindLanding/Banner"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"

const FactFindLanding = () => {
  return (
    <Layout>
      <SEO />
      <Navbar />
      <Banner />
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default FactFindLanding
