import React, {useEffect, useState} from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import bannerImg from "../../assets/images/FactFindLanding/icon-easy-online.svg"

const Banner = () => {
  const [query, setQuery] = useState("")
  useEffect(() => {
    console.log("query", new URL(window.location.href).search)
    setQuery(new URL(window.location.href).search)
  },[])

  return (
    <div className="home-hero-banner mb-md-5">
      <div className="container hero">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-10 col-lg-10">
            <div className="main-banner-content">
              <h1 className="text-center">
                Get a Better Home Loan.<br></br>Hassle Free.
              </h1>
              <ReactWOW delay=".2s" animation="fadeInDown">
                <div className="main-banner-image mt-4 mt-lg-5">
                  <img loading="eager" src={bannerImg} alt="banner" />
                  {/* <StaticImage
                    src="../../assets/images/hero.svg"
                    placeholder="tracedSVG"
                    layout="fullWidth"
                    quality="100"
                  /> */}
                </div>
              </ReactWOW>

              <h2 className="text-center mt-4 mt-lg-5">
                Find a better home loan in{" "}
                <span className="green-accent">5</span> minutes
              </h2>
              <ReactWOW delay=".2s" animation="fadeInUp">
                <div className="btn-box mt-4 text-center">
                  <a
                    href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030${query}`}
                    className="default-btn mid-width-btn primary-btn btn-lg mb-2 mr-0 mx-2"
                  >
                    Apply Now<span></span>
                  </a>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
      <div className="container highlights mt-5">
        <div className="row d-flex justify-stats">
          <div className="col-6 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>$10+ Billion</h3>
              <p>in home loans settled</p>
            </div>
          </div>

          <div className="col-6 col-sm-4  col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>27,000+</h3>
              <p>customers served</p>
            </div>
          </div>

          <div className="col-6 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>19 Years</h3>
              <p>of service and counting</p>
            </div>
          </div>

          <div className="col-6 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>8x Winner</h3>
              <p>MFAA &amp; AFG Brokerage of the Year</p>
            </div>
          </div>

          <div className="col-6 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>53+</h3>
              <p>Locations + Apply Online</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
